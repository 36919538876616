@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?lo8kv6');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?lo8kv6#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?lo8kv6') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?lo8kv6') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?lo8kv6##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clipboard-check-fill {
  &:before {
    content: $icon-clipboard-check-fill; 
  }
}
.icon-confounded-square {
  &:before {
    content: $icon-confounded-square; 
  }
}
.icon-facemask {
  &:before {
    content: $icon-facemask; 
  }
}
.icon-document-add-fill {
  &:before {
    content: $icon-document-add-fill; 
  }
}
.icon-chevron-up-circle-fill {
  &:before {
    content: $icon-chevron-up-circle-fill; 
  }
}
.icon-sad {
  &:before {
    content: $icon-sad; 
  }
}
.icon-user-circle {
  &:before {
    content: $icon-user-circle; 
  }
}
.icon-folder-open {
  &:before {
    content: $icon-folder-open; 
  }
}
.icon-add-square {
  &:before {
    content: $icon-add-square; 
  }
}
.icon-smoke {
  &:before {
    content: $icon-smoke; 
  }
}
.icon-pills {
  &:before {
    content: $icon-pills; 
  }
}
.icon-test {
  &:before {
    content: $icon-test; 
  }
}
.icon-dropper {
  &:before {
    content: $icon-dropper; 
  }
}
.icon-gallery {
  &:before {
    content: $icon-gallery; 
  }
}
.icon-gallery-edit {
  &:before {
    content: $icon-gallery-edit; 
  }
}
.icon-users-group-fill {
  &:before {
    content: $icon-users-group-fill; 
  }
}
.icon-chat-fill {
  &:before {
    content: $icon-chat-fill; 
  }
}
.icon-chat {
  &:before {
    content: $icon-chat; 
  }
}
.icon-star-fill {
  &:before {
    content: $icon-star-fill; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-archive-down {
  &:before {
    content: $icon-archive-down; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-check-square {
  &:before {
    content: $icon-check-square; 
  }
}
.icon-chevron-down-circle {
  &:before {
    content: $icon-chevron-down-circle; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-close-square {
  &:before {
    content: $icon-close-square; 
  }
}
.icon-document-add {
  &:before {
    content: $icon-document-add; 
  }
}
.icon-document {
  &:before {
    content: $icon-document; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-file-download {
  &:before {
    content: $icon-file-download; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-graph {
  &:before {
    content: $icon-graph; 
  }
}
.icon-history {
  &:before {
    content: $icon-history; 
  }
}
.icon-info-circle {
  &:before {
    content: $icon-info-circle; 
  }
}
.icon-key {
  &:before {
    content: $icon-key; 
  }
}
.icon-logout {
  &:before {
    content: $icon-logout; 
  }
}
.icon-maximize-square {
  &:before {
    content: $icon-maximize-square; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-move {
  &:before {
    content: $icon-move; 
  }
}
.icon-pen-square {
  &:before {
    content: $icon-pen-square; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-user-plus {
  &:before {
    content: $icon-user-plus; 
  }
}
.icon-medical-kit {
  &:before {
    content: $icon-medical-kit; 
  }
}
.icon-doc-add {
  &:before {
    content: $icon-doc-add; 
  }
}
.icon-time {
  &:before {
    content: $icon-time; 
  }
}
.icon-revote {
  &:before {
    content: $icon-revote; 
  }
}
.icon-docs {
  &:before {
    content: $icon-docs; 
  }
}
.icon-users-group {
  &:before {
    content: $icon-users-group; 
  }
}
.icon-notebook {
  &:before {
    content: $icon-notebook; 
  }
}
.icon-gadget {
  &:before {
    content: $icon-gadget; 
  }
}
.icon-heart-pulse {
  &:before {
    content: $icon-heart-pulse; 
  }
}
.icon-hospital {
  &:before {
    content: $icon-hospital; 
  }
}
.icon-notification-on {
  &:before {
    content: $icon-notification-on;
  }
}
.icon-book-open {
  &:before {
    content: $icon-book-open;
  }
}
.icon-salamat-logo {
  &:before {
    content: $icon-salamat-logo;
  }
}
.icon-tamin-logo {
  &:before {
    content: $icon-tamin-logo;
  }
}
.icon-arrow-diagonal {
  &:before {
    content: $icon-arrow-diagonal;
  }
}
.icon-video {
  &:before {
    content: $icon-video;
  }
}
.icon-microphone-mute {
  &:before {
    content: $icon-microphone-mute;
  }
}
.icon-minus-circle {
  &:before {
    content: $icon-minus-circle;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-video-close {
  &:before {
    content: $icon-video-close;
  }
}
.icon-attach {
  &:before {
    content: $icon-attach;
  }
}
.icon-microphone {
  &:before {
    content: $icon-microphone;
  }
}
.icon-profile-2user {
  &:before {
    content: $icon-profile-2user;
  }
}
.icon-messages {
  &:before {
    content: $icon-messages;
  }
}
.icon-exit-right {
  &:before {
    content: $icon-exit-right;
  }
}
.icon-dots-vertical {
  &:before {
    content: $icon-dots-vertical;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.icon-dashboard-admission {
  &:before {
    content: $icon-dashboard-admission;
  }
}
.icon-dashboard-capsules {
  &:before {
    content: $icon-dashboard-capsules;
  }
}
.icon-microscope {
  &:before {
    content: $icon-microscope;
  }
}
.icon-stethoscope {
  &:before {
    content: $icon-stethoscope;
  }
}
.icon-alarm {
  &:before {
    content: $icon-alarm;
  }
}
.icon-user-cross {
  &:before {
    content: $icon-user-cross;
  }
}
.icon-color-swatch {
  &:before {
    content: $icon-color-swatch;
  }
}
.icon-chat-close {
  &:before {
    content: $icon-chat-close;
  }
}
.icon-clipboard-check {
  &:before {
    content: $icon-clipboard-check;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-ai {
  &:before {
    content: $icon-ai;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-mailbox {
  &:before {
    content: $icon-mailbox;
  }
}
.icon-screenshare {
  &:before {
    content: $icon-screenshare;
  }
}