
// filter list 
.filter-list-box{
  margin: 0;
  display: flex;
  justify-content: center;
  .flt-item{
    margin: 0 8px;
    padding: 10px;
    border-radius: $borderRadius;
    cursor: pointer;
    transition: all .2s ease-in;
    &:hover,
    &.selected-item{
      color: $green;
      background-color: $lightGreen;
    }
    &.selected-item{
      font-weight: 600;
    }
  }
}

// badge
.badge{
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  transition: none;
  &.badge-blue{
    color: #2979FF;
    background-color: #E3F2FD;
  }
  &.badge-red{
    color: #E53935;
    background-color: #FFEBEE;
  }
  &.badge-orange{
    color: $orange;
    background-color: #F9F4E3;
  }
  &.badge-green{
    color: #08AE51;
    background-color: #E8F5E9;
  }
  &.badge-purple{
    color: #9C27B0;
    background-color: #F3E5F5;
  }
  &.badge-gray{
    color: #FFF;
    background-color: $lightGray;
  }
}

// star icon
.star-icon{
  font-size: 16px;
  color: #bdbdbd;
  &.fill-star{
    color: $orange;
  }
}

.modal{
  padding: 0 !important;
}
.modal-90w{ 
  width: 90%;
  max-width: none !important;
}
.modal-full{ 
  width: calc(100% - 20px);
  max-width: none !important;
}
.modal-dialog{
  margin: 0 auto;
}
.modal-content{
  border-radius: 10px;
  border: none;
  .modal-header{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .04);
    border: none;
  }
  .close-btn{
    position: absolute;
    top: 10px;
    #{$startDir}: 10px;
    color: $lightGray;
    font-size: 20px;
    cursor: pointer;
    transition: color .2s ease-in;
    &:hover{
      color: $black;
    }
  }
  .modal-txt{ // just used for privacy modal
    color: #757575;
    font-size: 16px;
    line-height: 1.6;
  }
  .modal-footer{
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.04);
    border: none;
  }
}

.modal-backdrop{
  background-color: #2a2a3929;
  backdrop-filter: blur(0.375rem);
  z-index: 1050;
  &.show{
    opacity: 1 ;
  }
}

.del-image-btn{
  position: absolute;
  #{$endDir}: 15px;
  top: 15px;
  font-size: 18px;
  transition: all .1s ease-in;
  &:hover {
    transform: scale(1.1);
    color: $red;
  }
}

.error-text-cover{
  position: absolute;
  background: #0000006e;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.btn-arrow-right,
.btn-arrow-left{
  transform: rotate(if($startDir == 'right' , 0, 180deg));
}

@media (min-width: 992px) {
  .row-cols-lg-8 > * {
    flex: 0 0 auto;
    width: 12.5%;
  }
}

// react multi datepicker
.rmdp-wrapper .rmdp-time-picker div input{
  width: 30px;
}

.profile-menu{
  .dropdown-toggle{
    color: $gray2;
    border: none;
    background-color: transparent;
    padding: 5px;
  }
  .dropdown-menu{
    font-size: 12px;
    .dropdown-item{
      text-align: right;
      padding: 10px 16px;
    }
  }
}

.online-status-menu{
  .dropdown-toggle{
    color: $gray2;
    border: none;
    background-color: transparent;
    padding: 5px;
  }
  .dropdown-header {
    padding-bottom: 0px;
  }
  .nav-link {
    padding-bottom: 2px;
  }
  .nav-link.active {
    border-bottom: solid 2px black !important;
  }
  .dropdown-menu{
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 12px;
    border-top-right-radius: 0;
    .dropdown-item{
      text-align: right;
      padding: 10px 16px;
    }
  }
}

.visit-status-menu{
  .dropdown-toggle{
    color: $gray2;
    border: none;
    background-color: transparent;
    padding: 5px;
  }
  .dropdown-menu{
    padding-top: 8px;
    font-size: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-top-right-radius: 10px;
    .dropdown-item{
      text-align: right;
      padding: 10px 16px;
    }
  }
}


.video-call-split-dropdown{
  .dropdown-toggle{
    color: $gray2;
    border: none;
    background-color: transparent;
    padding: 5px;
  }
  .dropdown-menu{
    font-size: 12px;
    .dropdown-item{
      text-align: right;
      padding: 10px 16px;
    }
  }
}

.accordion-header{
  .accordion-button{
    display: flex;
    flex-direction: row-reverse;
  }
}

.custom-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar{
    width: 5px;
  }
  &::-webkit-scrollbar-track{
    background: transparent;
  }
  &::-webkit-scrollbar-thumb{
    background-color: $gray4;
    border-radius: 25px;
  }
  scrollbar-width: thin;

}