body{
  &.advanced-report-collapsed{
    .advanced-report-button{
      transform: rotate(-90deg) translateY(-200%);
    }
    .advanced-report-cont{
      transform: translateX(0);
    }
  }
}

.advanced-report-button{
  position: fixed;
  top: 50px;
  #{$endDir}: 0;
  z-index: 9000;
  transform: rotate(if($startDir == 'right' , -90deg, 90deg)) translateY(-53px);
  transition: transform .1s ease-in;
  &:hover{ transform: rotate(if($startDir == 'right' , -90deg, 90deg)) translateY(-40px) }
}

.advanced-report-cont{
  position: fixed;
  top: 0;
  #{$endDir}: 0;
  transform: translateX(if($startDir == 'right' , -810px, 810px));
  bottom: 0;
  width: 810px;
  max-width: 100%;
  background-color: #2a2a3963;
  box-shadow: rgb(255 255 255 / 90%) 0rem 0rem 0.0625rem 0.0625rem inset, 
              rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
  backdrop-filter: blur(1.875rem);
  transition: all 0.2s $collapsedTransition;
  overflow-y: auto;
  z-index: 1100;
  &::-webkit-scrollbar{
    width: 5px;
  }
  &::-webkit-scrollbar-track{
    background: transparent;
  }
  &::-webkit-scrollbar-thumb{
    background-color: #7ab7ff;
    border-radius: 25px;
  }
  .close-advanced-report-btn{
    position: absolute;
    top: 25px;
    #{$startDir}: 20px;
  }
}