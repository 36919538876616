
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{ margin: 0 }
textarea{ resize: none }
a, a:hover{
  text-decoration: none;
  color: $black
}
ul{
  margin: 0;
  padding: 0;
  list-style: none;
}
input, input:focus{ box-shadow: none !important }

.dropdown-toggle{
  &:after {
    display: none !important;
  }
  &:focus{
    box-shadow: unset !important;
  }
}