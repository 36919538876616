//@font-face {
//	font-family: Pelak;
//	font-style: normal;
//	font-weight: 900;
//	src: url('../fonts/pelak/eot/PelakFA-Black.eot');
//	src: url('../fonts/pelak/eot/PelakFA-Black.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
//		 url('../fonts/pelak/woff2/PelakFA-Black.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
//		 url('../fonts/pelak/woff/PelakFA-Black.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//}
//@font-face {
//	font-family: Pelak;
//	font-style: normal;
//	font-weight: bold;
//	src: url('../fonts/pelak/eot/PelakFA-Bold.eot');
//	src: url('../fonts/pelak/eot/PelakFA-Bold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
//		 url('../fonts/pelak/woff2/PelakFA-Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
//		 url('../fonts/pelak/woff/PelakFA-Bold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//}
//@font-face {
//	font-family: Pelak;
//	font-style: normal;
//	font-weight: 500;
//	src: url('../fonts/pelak/eot/PelakFA-Medium.eot');
//	src: url('../fonts/pelak/eot/PelakFA-Medium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
//		 url('../fonts/pelak/woff2/PelakFA-Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
//		 url('../fonts/pelak/woff/PelakFA-Medium.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//}
//@font-face {
//	font-family: Pelak;
//	font-style: normal;
//	font-weight: 300;
//	src: url('../fonts/pelak/eot/PelakFA-Light.eot');
//	src: url('../fonts/pelak/eot/PelakFA-Light.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
//		 url('../fonts/pelak/woff2/PelakFA-Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
//		 url('../fonts/pelak/woff/PelakFA-Light.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//}
//@font-face {
//	font-family: Pelak;
//	font-style: normal;
//	font-weight: 200;
//	src: url('../fonts/pelak/eot/PelakFA-Thin.eot');
//	src: url('../fonts/pelak/eot/PelakFA-Thin.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
//		 url('../fonts/pelak/woff2/PelakFA-Thin.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
//		 url('../fonts/pelak/woff/PelakFA-Thin.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//}
//@font-face {
//	font-family: Pelak;
//	font-style: normal;
//	font-weight: normal;
//	src: url('../fonts/pelak/eot/PelakFA-Regular.eot');
//	src: url('../fonts/pelak/eot/PelakFA-Regular.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
//		 url('../fonts/pelak/woff2/PelakFA-Regular.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
//		 url('../fonts/pelak/woff/PelakFA-Regular.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//}

//@font-face {
//  font-family: IranSansX;
//  font-style: normal;
//  font-weight: 900;
//  src: url('../fonts/iranSansX/eot/IRANSansWeb_Black.eot');
//  src: url('../fonts/iranSansX/eot/IRANSansWeb_Black.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
//  url('../fonts/iranSansX/woff2/IRANSansX-Black.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
//  url('../fonts/iranSansX/woff/IRANSansX-Black.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//}
//@font-face {
//  font-family: IranSansX;
//  font-style: normal;
//  font-weight: bold;
//  src: url('../fonts/iranSansX/eot/IRANSansWeb_Bold.eot');
//  src: url('../fonts/iranSansX/eot/IRANSansWeb_Bold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
//  url('../fonts/iranSansX/woff2/IRANSansX-Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
//  url('../fonts/iranSansX/woff/IRANSansX-Bold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//}
//@font-face {
//  font-family: IranSansX;
//  font-style: normal;
//  font-weight: 500;
//  src: url('../fonts/iranSansX/eot/IRANSansWeb_Medium.eot');
//  src: url('../fonts/iranSansX/eot/IRANSansWeb_Medium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
//  url('../fonts/iranSansX/woff2/IRANSansX-Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
//  url('../fonts/iranSansX/woff/IRANSansX-Medium.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//}
//@font-face {
//  font-family: IranSansX;
//  font-style: normal;
//  font-weight: 300;
//  src: url('../fonts/iranSansX/eot/IRANSansWeb_Light.eot');
//  src: url('../fonts/iranSansX/eot/IRANSansWeb_Light.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
//  url('../fonts/iranSansX/woff2/IRANSansX-Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
//  url('../fonts/iranSansX/woff/IRANSansX-Light.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//}
//@font-face {
//  font-family: IranSansX;
//  font-style: normal;
//  font-weight: 200;
//  src: url('../fonts/iranSansX/eot/IRANSansWeb_UltraLight.eot');
//  src: url('../fonts/iranSansX/eot/IRANSansWeb_UltraLight.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
//  url('../fonts/iranSansX/woff2/IRANSansX-Thin.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
//  url('../fonts/iranSansX/woff/IRANSansX-Thin.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//}
//@font-face {
//  font-family: IranSansX;
//  font-style: normal;
//  font-weight: normal;
//  src: url('../fonts/iranSansX/eot/IRANSansWeb.eot');
//  src: url('../fonts/iranSansX/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
//  url('../fonts/iranSansX/woff2/IRANSansX-Regular.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
//  url('../fonts/iranSansX/woff/IRANSansX-Regular.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
//}

@font-face {
  font-family: Vazir;
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/vazir/eot/Vazir-Black-FD-UI.eot');
  src: url('../fonts/vazir/eot/Vazir-Black-FD-UI.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/vazir/woff2/Vazir-Black-FD-UI.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('../fonts/vazir/woff/Vazir-Black-FD-UI.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Vazir;
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/vazir/eot/Vazir-Bold-FD-UI.eot');
  src: url('../fonts/vazir/eot/Vazir-Bold-FD-UI.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/vazir/woff2/Vazir-Bold-FD-UI.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('../fonts/vazir/woff/Vazir-Bold-FD-UI.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Vazir;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/vazir/eot/Vazir-Medium-FD-UI.eot');
  src: url('../fonts/vazir/eot/Vazir-Medium-FD-UI.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/vazir/woff2/Vazir-Medium-FD-UI.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('../fonts/vazir/woff/Vazir-Medium-FD-UI.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Vazir;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/vazir/eot/Vazir-Light-FD-UI.eot');
  src: url('../fonts/vazir/eot/Vazir-Light-FD-UI.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/vazir/woff2/Vazir-Light-FD-UI.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('../fonts/vazir/woff/Vazir-Light-FD-UI.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Vazir;
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/vazir/eot/Vazir-Thin-FD-UI.eot');
  src: url('../fonts/vazir/eot/Vazir-Thin-FD-UI.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/vazir/woff2/Vazir-Thin-FD-UI.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('../fonts/vazir/woff/Vazir-Thin-FD-UI.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Vazir;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/vazir/eot/Vazir-Regular-FD-UI.eot');
  src: url('../fonts/vazir/eot/Vazir-Regular-FD-UI.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('../fonts/vazir/woff2/Vazir-Regular-FD-UI.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('../fonts/vazir/woff/Vazir-Regular-FD-UI.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}