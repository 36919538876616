/* for test class */
.bg-yellow-500{
    background-color: #d2d209;
}
.bg-black-100{
    background-color: black;
}

.bg-gray-custom{
    background-color: #3f3e43;
}

.flex-grow-custom-50{
    flex: 0 1 calc(50% - .5rem);
}
.flex-grow-custom-100 {
    flex: 0 1 100%;
}
.camStatus{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50% , -50%);
}

.micStatus{
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 35px;
    height: 35px;
    background-color: #2b2b2b;
    position: absolute;
    top: 50px;
    left: 10px;
}


.scrollbar-custom::-webkit-scrollbar {
    /* استایل نوار اسکرول */
    width: 12px;
    cursor:pointer;
}

.scrollbar-custom::-webkit-scrollbar-track {
    /* استایل پیست نوار اسکرول */
    background-color: #fafafa;
    cursor:pointer;

}

.scrollbar-custom::-webkit-scrollbar-thumb {
    /* استایل قسمت قابل حرکت نوار اسکرول */
    background-color: #bdbdbd;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
    cursor:pointer;

}




/* For Firefox */
.scrollbar-custom {
    scrollbar-width: thin;
    scrollbar-color: #bdbdbd #fafafa;
    cursor: pointer;
    border-radius: 10px;
}



/* For Firefox thumb */
.scrollbar-custom::-moz-scrollbar-thumb {
    background-color: #1f1f2f;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

/*-----------------------------SideBar ScrollBar---------------------------------*/
.scrollbar-custom-sidebar::-webkit-scrollbar {
    /* استایل نوار اسکرول */
    width: 12px;
    cursor:pointer;
}

.scrollbar-custom-sidebar::-webkit-scrollbar-track {
    /* استایل پیست نوار اسکرول */
    background-color: #1f1f2f;
    cursor:pointer;

}

.scrollbar-custom-sidebar::-webkit-scrollbar-thumb {
    /* استایل قسمت قابل حرکت نوار اسکرول */
    background-color: #1f1f2f;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
    cursor:pointer;

}




/* For Firefox */
.scrollbar-custom-sidebar {
    background-color: #1f1f2f;
    scrollbar-width: thin;
    scrollbar-color: #bdbdbd #1f1f2f;
    cursor: pointer;
    border-radius: 10px;
}



/* For Firefox thumb */
.scrollbar-custom-sidebar::-moz-scrollbar-thumb {
    background-color: #1f1f2f;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}
/*---------------------------Lobby ScrollBar---------------------------*/
.scrollbar-custom-lobby::-webkit-scrollbar {
    /* استایل نوار اسکرول */
    width: 12px;
    cursor:pointer;
}

.scrollbar-custom-lobby::-webkit-scrollbar-track {
    /* استایل پیست نوار اسکرول */
    background-color: #2b2b2b;
    cursor:pointer;

}

.scrollbar-custom-lobby::-webkit-scrollbar-thumb {
    /* استایل قسمت قابل حرکت نوار اسکرول */
    background-color: #2b2b2b;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
    cursor:pointer;

}




/* For Firefox */
.scrollbar-custom-lobby {
    background-color: #2b2b2b;
    scrollbar-width: thin;
    scrollbar-color: #bdbdbd #2b2b2b;
    cursor: pointer;
    border-radius: 10px;
}



/* For Firefox thumb */
.scrollbar-custom-lobby::-moz-scrollbar-thumb {
    background-color: #2b2b2b;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}
/* ------------ width Styles ------------- */

/* width 100% */
.width-full{
    width: 100%;
}
/* width 95 percent */
.w-95-per{
    width: 95% !important
}
.w-98-per{
    width: 98% !important
}
.w-75-per{
    width: 75%
}
/* width 25 percent */
.w-25-per{
    width: 25%;
}

/* ------------ Height Styles ------------- */
.height-screen-100{
  height: 100vh !important;
}
/* height 8 percent */
.h-8-per{
    height: 8%;
}

.h-84-per{
    height: 84%;
}

.h-50-per{
    height: 50%;
}
.h-45-per{
    height: 45%;
}
.h-300-p{
    height: 300px !important;
}
.h-100-important{
    height: 100% !important;
}
/* ------------ Margin Styles ------------- */
.margin-x-auto{
    margin-left: auto;
    margin-right: auto;
}

.margin-5{
    margin: 5px;
}

.margin-t-16{
    margin-top: 16px;
}

.margin-b-8{
    margin-bottom: 8px;
}
/* ------------ Padding Styles ------------- */
.padding-0{
    padding: 0 !important;
}
.padding-y-1{
    padding-top: 4px;
    padding-bottom: 4px;
}

.padding-b-2{
    padding-bottom:8px ;
}

/* ------------ Margin Styles ------------- */
.position-relative{
    position: relative !important;
}
.position-absolute{
    position: absolute;
}

.position-fixed-c{
    position: fixed;
}
.right-0-c{
    right: 0;
}
.bottom-0-c{
    bottom: 5px;
}
/* ------------ Radius Styles ------------- */
.radius-18{
    border-radius: 18px;
}
.radius-25{
    border-radius: 25px;
}

/* ------------ Font Styles ------------- */
.text-size-10{
    font-size: 10px;
}
.text-size-11{
    font-size: 11px;
}
.text-size-15{
    font-size: 15px;
}

.o-f-c{
    object-fit: cover !important;
}
.go3489369143 {
    display: none !important;
}

@media screen and (max-width: 567px){
    .lobby-xs{
        position: absolute;
        bottom: 5px;
        right: 0;
        width: auto;
    }
    .video-res{
        width: 100%;
    }
    .video-flex-wrap{
        display: flex;
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 768px){
    .lobby-xs{
        position: absolute;
        bottom: 5px;
        right: 0;
        width: auto;
    }
    .video-res{
        width: 100%;
    }
}
@media screen and (max-width: 992px){
    .lobby-xs{
        position: absolute;
        bottom: 5px;
        right: 0;
        width: auto;
    }
    .video-res{
        width: 100%;
    }
}

.b-2-red{
    border: 3px #b70202 solid;
}