body{
  &.sidebar-collapsed{
    .sidebar{
      transform: translateX(if($startDir == 'right' , $sidebarWidth, -340px));
      @include breakpoint('md') {
        transform: translateX(0px);
        width: $sidebarCollapsedWidth;
        .hide-collapsed{ display: none !important }
      }
      .sidebar-content{
        .sidebar-menu{
          .item-lst{
            width: 49px;
            &.subroutes-box{
              & > .item-lnk{
                &:hover{ background-color: transparent; }
                .chevron-down{
                  margin-#{$endDir}: -20px;
                  i{ font-size: 10px }
                }
              }
              .subroutes-items{
                margin-#{$startDir}: 0;
                padding-#{$startDir}: 12px;
                .item-lnk{
                  width: 49px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.sidebar{
  position: fixed;
  top: 0;
  bottom: 0;
  #{$startDir}: 0;
  width: $sidebarWidth;
  max-width: 100%;
  color: #FFF;
  transition: all 0.2s $collapsedTransition;
  z-index: 15;
  @include breakpoint('md') {
    padding: 20px;
  }
  .sidebar-content{
    padding-bottom: 100px;
    background-color: $dark;
    border-radius: if($startDir == 'right' , 18px 0 0 18px, 0 18px 18px 0);
    overflow: hidden;
    position: relative;
    @include breakpoint('md') {
      border-radius: 18px;
    }
    .sidebar-menu{
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar{
        width: 5px;
      }
      &::-webkit-scrollbar-track{
        background: transparent;
      }
      &::-webkit-scrollbar-thumb{
        background-color: #E9F1F9;
        border-radius: 25px;
        border: 1px solid $dark;
      }
      .item-lst{
        margin: 6px 0;
        width: 100%;
        transition: all 0.2s $collapsedTransition;
        &.subroutes-box{
          & > .item-lnk{
            &:hover{ background-color: transparent; }
          }
          .subroutes-items{
            border-#{$startDir}: 2px solid #4a4a57;
            margin-#{$startDir}: 24px;
            padding-#{$startDir}: 16px;
            transition: all 0.2s $collapsedTransition;
          }
        }
        .item-lnk{
          display: flex;
          align-items: center;
          padding: 12px;
          color: #FFF;
          border-radius: $borderRadius;
          transition: background-color .1s ease-in;
          position: relative;
          .chevron-down{
            i{ font-size: 14px }
          }
          i{ font-size: 22px }
          .item-txt{ margin-#{$startDir}: 18px }
          .arrow-box{
            position: absolute;
            top: 50%;
            #{$startDir}: 10px;
            transform: translateY(-50%);
            i{ font-size: 15px }
          }
          &:hover{ background-color: #3f3f4c; }
          &.active{ background-color: $primary; }
        }
      }
      // .logout-cont{
      //   position: absolute;
      //   left: 24px;
      //   right: 24px;
      //   bottom: 20px;
      // }
    }
  }
}

.floating-button-container {
  position: absolute;
  top: 50%; /* Center vertically */
  left: -15px; /* Adjust the negative value based on half the button width */
  transform: translateY(-50%);
  border-radius: 11px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}