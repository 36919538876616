@mixin breakpoint($point) {

  @if $point==watch {
    @media (min-width: 160px) {
      @content;
    }
  }

  @else if $point==xxs {
    @media (min-width: 330px) {
      @content;
    }
  }

  @else if $point==xs {
    @media (min-width: 576px) {
      @content;
    }
  }

  @else if $point==sm {
    @media (min-width: 768px) {
      @content;
    }
  }

  @else if $point==md {
    @media (min-width: 992px) {
      @content;
    }
  }

  @else if $point==lg {
    @media (min-width: 1200px) {
      @content;
    }
  }

  @else if $point==xl {
    @media (min-width: 1400px) {
      @content;
    }
  }

  @else {
    @media ($point) { @content; }
  }
}