.preview-box{
  &:hover{
    .preview-btn{
      opacity: 1;
    }
  }
  .preview-btn{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #8b8b8b8a;
    font-size: 19px;
    color: white;
    opacity: 0;
    transition: opacity .15s ease-in;
  }
}

.image-uploader-box{
  .pckg-image-box{
    &:hover{
      .pckg-image-box-action{
        opacity: 1;
      }
    }
    .pckg-image-box-action{
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; 
      background: linear-gradient(180deg, rgba(96, 96, 101, 0) 14.46%, rgb(31 31 47 / 91%) 94.32%);
      transition: opacity .2s ease-in;
      opacity: 0;
    }
  }
}

.main-package-badge{
  position: absolute;
  left: 5px;
  bottom: 5px;
  display: flex;
}

.title-dot{
  padding-right: 25px;
  position: relative;
  &:before{
    content: " ";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%) rotate(45deg);
    @include iSize(6px);
    background-color: #F6A60A;
  }
  &.tile-dot-primary{
    &:before{
      background-color: $primary;
    }
  }
}


.title-dot-primary{
  padding-right: 25px;
  position: relative;
  &:before{
    content: " ";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%) rotate(45deg);
    @include iSize(6px);
    background-color: $primary;
  }
  &.tile-dot-primary{
    &:before{
      background-color: $primary;
    }
  }
}

.delete-item-bg{
  width: 250px;
  height: 90px;
  background: url('../../images/image/delete-bg.png') no-repeat center center / cover;
}

.info-item-bg{
  width: 250px;
  height: 90px;
  background: url('../../images/image/info-bg.png') no-repeat center center / cover;
}

.warning-item-bg{
  width: 250px;
  height: 90px;
  background: url('../../images/image/warning-bg.svg') no-repeat center center / cover;
}

.timeline-container{
  margin: 0 -25px -30px;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 12px 12px;
  overflow: hidden;
  .tmln-row{
    display: flex;
    height: 80px;
    .tmln-cell{
      // background-color: red;
      flex: 1;
      padding: 20px;
      text-align: center;
      &:first-child{
        width: 120px;
        max-width: 120px;
        min-width: 120px;
        background-color: #ededee;
        border-bottom: 1px solid #e6e6e6;
      }
      &:not(:first-child){
        border-bottom: 1px dashed #d3d3d6;
      }
      .timeline-filled-cell{
        &.visit-type-100{
          background-color: #f8e4cd;
        }
        &.visit-type-300{
          background-color: #cdd6f8;
        }
      }
      &.tmln-cube-cell{
        display: flex;
        padding: 0;
        .tmln-cube{
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          bottom: 0;
          opacity: .8;
          transition: all .2s ease-in;
          &:hover {
            // box-shadow: 0 0 2px 2px #e2e2e2;
            // border: 1px dashed #a8a8a8;
            opacity: 1;
          }
        }
      }
    }
    &:last-child{
      .tmln-cell{
        border-bottom: none;
      }
    }
  }
  .tmln-thead{
    border-bottom: 1px solid #e4e4e4;
    font-family: sans-serif;
    .tmln-cell{
      display: flex;
      align-items: center;
      text-align: right;
      padding: 20px 0px;
      // border: 1px dashed #e5e5e5;
      position: relative;
      &:first-child{
        background-color: transparent;
      }
      &:not(:first-child):before{
        content: " ";
        position: absolute;
        right: 0;
        height: 25px;
        border-right: 1px dashed #dadada;
        bottom: 0;
      }
      .tmln-time{
        display: block;
        position: absolute;
        right: 0;
        transform: translateX(17px);
      }
    }
  }
}