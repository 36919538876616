.prescription-category-nav {
  .nav-pills,
  .nav-link.active{
    color: var(--bs-nav-pills-link-active-color);
    background-color: $primary;
    &:hover{ background-color: darken($primary , 10%); }
  }

  .nav-link {
    background-color: $gray-disabled;
    color: $black;
    &:hover{ background-color: darken($gray-disabled , 10%); }
  }
}

.ecg-lead-nav {
  .nav-pills,
  .nav-link.active{
    color: $black;
    background-color: transparent;
    border: 1px solid $blue;
    border-radius: 6px;
    &:hover{
      background-color: transparent;
      border-radius: 6px;
    }
  }

  .nav-link {
    background-color: transparent;
    color: $black;
    &:hover{
      background-color: darken($gray-disabled , 10%);
      border-radius: 6px;
    }
  }
}

.visits-accordion {
  .accordion-button {
    background-color: rgba(69, 72, 85, 0.05) !important;
    border-radius: var(--bs-accordion-inner-border-radius);
  }

  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    background-color: #FFFFFF !important;
    color: #212529;
  }

  .accordion-button:after {
    margin-left: 12px;
    background-image: url('data:image/svg+xml,<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1.75C5.89137 1.75 1.75 5.89137 1.75 11C1.75 16.1086 5.89137 20.25 11 20.25C16.1086 20.25 20.25 16.1086 20.25 11C20.25 5.89137 16.1086 1.75 11 1.75ZM0.25 11C0.25 5.06294 5.06294 0.25 11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C5.06294 21.75 0.25 16.9371 0.25 11ZM7.46967 8.96967C7.76256 8.67678 8.23744 8.67678 8.53033 8.96967L11 11.4393L13.4697 8.96967C13.7626 8.67678 14.2374 8.67678 14.5303 8.96967C14.8232 9.26256 14.8232 9.73744 14.5303 10.0303L11.5303 13.0303C11.2374 13.3232 10.7626 13.3232 10.4697 13.0303L7.46967 10.0303C7.17678 9.73744 7.17678 9.26256 7.46967 8.96967Z" fill="%23454855"/></svg>') !important;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.5303 9.96967C15.8232 10.2626 15.8232 10.7374 15.5303 11.0303L12.5303 14.0303C12.3897 14.171 12.1989 14.25 12 14.25C11.8011 14.25 11.6103 14.171 11.4697 14.0303L8.46967 11.0303C8.17678 10.7374 8.17678 10.2626 8.46967 9.96967C8.76256 9.67678 9.23744 9.67678 9.53033 9.96967L12 12.4393L14.4697 9.96967C14.7626 9.67678 15.2374 9.67678 15.5303 9.96967Z" fill="%232E57FF"/></svg>') !important;
    color: #212529;
  }
}

.visit-tab-content {
  .tab-content {
    width: 100% !important;
  }
}

.visit-footer{
  height: $headerHeight;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 20px 10px;
  transition: #{$startDir} .2s $collapsedTransition, top .2s $collapsedTransition;
  z-index: 10;
  // filter: blur(6px);
  @include breakpoint('sm') {
    padding: 20px;
  }
  @include breakpoint('md') {
    #{$startDir}: $sidebarWidth;
    // filter: blur(0);
  }
  &.scrolling-position {
    top: -15px;
  }
  &.scrolling-shadow {
    .footer-cont{
      box-shadow: rgb(255 255 255 / 90%) 0rem 0rem 0.0625rem 0.0625rem inset,
      rgb(0 0 0 / 10%) 0rem 0rem 1.6875rem 0rem;
    }
  }
  .footer-cont{
    border-radius: 12px;
    background-color: #FFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
    .breadcrumb-item{
      font-size: 12px;
      &.active{ font-weight: 600; }
      & + .breadcrumb-item::before{
        float: $startDir;
        padding-#{$endDir}: .5rem;
        padding-#{$startDir}: 0;
      }
    }
  }
}

.prescription-items-accordion {
  .accordion-button {
    background-color: #E9EEFF !important;
    border-radius: var(--bs-accordion-inner-border-radius);
    display: flex;
    flex-direction: column;
  }

  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    background-color: #E9EEFF !important;
    color: #212529;
  }

  .accordion-button:after {
    margin-left: 12px;
    display: none;
  }

  .accordion-button:not(.collapsed)::after {
    color: #212529;
    display: none;
  }
}

.accordion-title {
  position: relative;
}

.accordion-icon {
  display: flex;
  align-items: center;
}

.accordion-title span {
  flex: 1;
}

.accordion-icon svg {
  display: block; /* Ensures the SVG behaves like a block element */
  margin-left: 10px; /* Space between the text and the SVG */
  transition: transform 0.3s ease, fill 0.3s ease; /* Smooth transitions for rotation and color */
  fill: #454855; /* Default color */
}

.accordion-button:not(.collapsed) .accordion-title .accordion-icon svg {
  transform: rotate(180deg); /* Rotate the SVG when the accordion is open */
  fill: blue; /* Change color to blue when the accordion is open */
}

.prescription-accordion-footer {
  padding: 0 20px 10px 20px;
}